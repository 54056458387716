import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { axios } from '@/lib/axios';
import { FeatureFlags } from '@/types/featureFlags';

export const useFeatureFlags = (queryProps?: UseQueryOptions<FeatureFlags>) => {
  return useQuery<FeatureFlags>(
    ['featureFlags'],
    async () => {
      return axios.get('/feature_flags');
    },
    {
      ...queryProps,
    }
  );
};
