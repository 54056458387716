import { getAccount, updateAccount } from '@/api';
import type { AccountInfoRequest, AccountInfoResponse } from '@/types/accounts';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';

export function useAccount(): UseQueryResult<AccountInfoResponse, unknown> {
  return useQuery<AccountInfoResponse>(['accountInfo'], getAccount);
}

export function useUpdateAccount(
  queryProps: UseMutationOptions<AccountInfoResponse, Error, AccountInfoRequest>
) {
  return useMutation<AccountInfoResponse, Error, AccountInfoRequest>(updateAccount, queryProps);
}
