import { Suspense } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from '@/lib/notistack';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { queryClient } from '@/lib/react-query';
import { GeneralErrorFallback } from '@/components/ErrorFallback';
import { theme as fractalTheme } from '@commercefoundation/fractal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { NotificationProvider } from './NotificationProvider';
import { appsignal } from '@/lib/appsignal';
import { ErrorBoundary } from '@appsignal/react';
import { routes } from '@/routes';

const theme = createTheme(
  // @ts-ignore
  fractalTheme
);

export const AppProvider = () => {
  const router = createBrowserRouter(routes);

  return (
    <Suspense fallback={<></>}>
      <ErrorBoundary instance={appsignal} fallback={GeneralErrorFallback}>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <NotificationProvider>
                <RouterProvider router={router} />
              </NotificationProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </ErrorBoundary>
    </Suspense>
  );
};
