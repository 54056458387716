import { Box } from '@mui/material';
import SomthingWentWrong from '@/assets/images/illustrations/something-went-wrong.svg';
import { Button, Icon, Typography, theme } from '@commercefoundation/fractal';

export const GeneralErrorFallback = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      minHeight={{ desktop: '700px' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.primary['25']}
      px="1rem"
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="1rem"
        maxWidth="560px"
      >
        <Typography
          fontWeight="semibold"
          type="heading"
          fontSize="sm"
          color={theme.palette.gray['900']}
          textAlign="center"
        >
          Oops! Something went wrong!
        </Typography>
        <Typography fontSize="xl" color={theme.palette.gray['600']} textAlign="center">
          There was a problem connecting with Foundation. Please refresh the page or check your
          connections.
        </Typography>
        <Button
          size="lg"
          variant="link-primary"
          startIcon={<Icon name="repeat-04" size="xs" />}
          onClick={() => location.reload()}
        >
          Refresh
        </Button>
        <Box
          display={{ mobile: 'none', desktop: 'block' }}
          position="absolute"
          width="414px"
          height="687px"
          left="-212px"
          top="-212px"
        >
          <img
            src={SomthingWentWrong}
            alt="something went wrong"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  );
};
