import { useMutation } from '@tanstack/react-query';

import { axios } from '@/lib/axios';
import type { MutationConfig } from '@/lib/react-query';

const signout = (): Promise<void> => {
  return axios.delete('/users/sign_out');
};

type UseSignoutOptions = {
  config?: MutationConfig<typeof signout>;
};

export const useSignout = ({ config }: UseSignoutOptions = {}) => {
  return useMutation(signout, config);
};
