import { createRoot } from 'react-dom/client';
import { initChmln } from '@/lib/chamaeleon';
import { App } from '../App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}

if (
  import.meta.env['VITE_ENABLED_MOCK_SERVER'] === 'true' &&
  import.meta.env.MODE === 'development'
) {
  const { worker } = await import('../mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

initChmln();
