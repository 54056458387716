import { axios } from '@/lib/axios';
import type { AccountInfoRequest, AccountInfoResponse } from '@/types/accounts';

export async function getAccount(): Promise<AccountInfoResponse> {
  return await axios.get('/account');
}

export async function updateAccount(data: AccountInfoRequest): Promise<AccountInfoResponse> {
  return await axios.put('/account', data);
}
